
<template>
  <div>
    <nav class="navbar navbar-transparent navbar-absolute">
      <div class="container">
        <div class="navbar-header">
          <router-link class="navbar-brand" to="/">
            sendform
          </router-link>
        </div>
      </div>
    </nav>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page" data-color="light">
        <!--data-image="static/img/background/background-2.jpg" -->
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <h1 class="text-title">
                  Send your form data to your
                  <span class="text-danger">
                    <client-only :placeholder="typer.join(', ')">
                      <vue-typer
                        :text="typer"
                        :repeat="Infinity"
                        :shuffle="false"
                        :pre-type-delay="70"
                        :type-delay="70"
                        :pre-erase-delay="2000"
                        :erase-delay="250"
                        :erase-on-complete="false"
                        initial-action="erasing"
                        erase-style="backspace"
                        caret-animation="blink"
                      />
                    </client-only>
                  </span>
                </h1>
                <h2 style="font-family: 'Muli';">
                  Notify the right people instantly
                </h2>
                <p>Your form, your design. No login, no server, no API, no credit card required</p>
              </div>
              <div class="col-sm-6 col-xs-12 center-block text-center hidden-xs">
                <img src="/img/landing/form_arrived.svg" class="img-responsive center-block text-center" width="60%">
              </div>
            </div>
            <div class="row mt-15">
              <div class="col-12 text-center">
                <button class="btn btn-black btn-simple disabled">coming soon</button>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="copyright">
              &copy; 2020 sendform
            </div>
          </div>
        </footer>
        <!--div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Landing',
  layout: 'fullpage',
  head: {
    title: 'Send forms without code | sendform',
    meta: [
      { hid: 'description', name: 'description', content: 'sendform is an automated form backend, API, email and notification service for HTML and JSON forms. It is the simplest way to embed custom contact forms, order forms, or email capture forms on your website.' }
    ]
  },
  data () {
    return {
      typer: ['email', 'slack', 'telegram', 'trello']
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/sass/paper/_variables';

.text-title {
  font-weight: 700
}

.navbar.navbar-absolute {
    position: absolute;
    width: 100%;
    z-index: 1030;
    color: $black-color !important;
}
.navbar-transparent .navbar-brand,
.footer .copyright,
.footer .copyright a {
  color: $black-color !important;
}

@media (max-width: 991px) {
  .navbar-transparent .navbar-brand {
    color: $white-color !important;
  }

  .text-title {
    font-size: 40px;
  }
}

.full-page > .content {
  padding-top: 20vh;
}

.sidebar {
  display: none;

  &.off-canvas-sidebar {
    display: inherit;
  }
}

// vue-typer
::v-deep .vue-typer .custom.char.typed {
  color: $danger-states-color !important;
}

::v-deep .vue-typer .custom.caret {
  margin-left: unset;
  vertical-align: unset;
  border-top:   unset;
  border-right: unset;
  border-left:  unset;
  color: $danger-states-color;
  background-color: $danger-states-color;
  &::before {
    color: $danger-states-color;
    background-color: $danger-states-color;
    content: "\200B";
  }
}
</style>
